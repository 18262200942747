class Parallax {
	constructor() {
		this.elements = document.querySelectorAll('[data-parallax]');
		this.elementsTwo = document.querySelectorAll('[data-parallax2]');
		this.moveElements();
		this.moveElementsTwo();
	}

	moveElements() {
		window.addEventListener('scroll', () => {
			const scrollPosition = window.scrollY;

			this.elements.forEach((element) => {
				const scrollSpeed = element.getAttribute('data-parallax');
				const parallaxValue = scrollPosition * scrollSpeed;
				element.style.transform = `translateY(${parallaxValue}px)`;
			});
		});
	}

	moveElementsTwo() {
		window.addEventListener('scroll', () => {
	
			const viewportHeight = window.innerHeight;
			const scrollPosition = window.scrollY;
	
			this.elementsTwo.forEach((element) => {
				const elementRect = element.getBoundingClientRect();
				const elementMid = elementRect.top + window.scrollY + elementRect.height / 2;
				const viewportMid = scrollPosition + viewportHeight / 2;
				const distanceFromMid = (elementMid - viewportMid) / viewportHeight;
	
				const scrollSpeed = element.getAttribute('data-parallax2') * -1000;
				const parallaxValue = distanceFromMid * scrollSpeed;
	
				element.style.transform = `translateY(${parallaxValue}px)`;
			});
		});
	}
}

const parallax = new Parallax();
