class LillyModal {
	constructor() {
		this.closeModalEvent();
	}
	openModal(id) {
		
		const template = document.querySelector(`#${id}`).cloneNode(true);
		const modal = document.createElement('div');
		modal.classList.add('modal');
		const modalContent = document.importNode(template.content, true);
		modal.appendChild(modalContent);
		document.body.appendChild(modal);
		new TextUtils();
		new LillyForm('#registration')
		
	}
	closeModal(el) {
		el.closest('.modal').remove();
	}
	closeModalCookie(el) {
		const modalId = el.closest('.modal').id;
		// Set a cookie to remember the user's choice for the current session
		document.cookie = `${modalId}=true; path=/`;
		el.closest('.modal').remove();
	}

	closeModalEvent() { 
		window.addEventListener('click', (e) => {
			if (e.target.classList.contains('close__button')) {
				this.closeModal(e.target);
			}
		});
	
	}
}

const lillyModal = new LillyModal();