class TextUtils {
	constructor() { 
		this.croppedText = document.querySelectorAll('[data-readmore]');
		this.setReadMoreText()
	}
	setReadMoreText() { 
		console.log(this.croppedText)
		this.croppedText.forEach((element) => {
			const html = element.innerHTML;
			const maxWordCount = element.getAttribute('data-readmore');
			const words = html.split(' ');
			const slicedHtml = words.slice(0, maxWordCount).join(' ');
			const readMoreHtml = '<span class="read-more-text">... mehr lesen</span>';
			const readLessHtml = '<span class="read-less-text" style="display: none;"> schließen</span>';
			const hiddenHtml = '<span class="hidden-text" style="display: none;"> ' + words.slice(maxWordCount).join(' ') + '</span>';
	
			element.innerHTML = slicedHtml + readMoreHtml + hiddenHtml + readLessHtml;
	
			element.querySelector('.read-more-text').addEventListener('click', (e) => {
				e.preventDefault()
				element.querySelector('.read-more-text').style.display = 'none';
				element.querySelector('.hidden-text').style.display = 'inline';
				element.querySelector('.read-less-text').style.display = 'inline';
			});
			element.querySelector('.read-less-text').addEventListener('click', (e) => {
				e.preventDefault()
				element.querySelector('.read-more-text').style.display = 'inline';
				element.querySelector('.hidden-text').style.display = 'none';
				element.querySelector('.read-less-text').style.display = 'none';
			});
		});
	}
}

const textUtils = new TextUtils();