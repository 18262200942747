class LillyForm {
	constructor() {
		// this.form.addEventListener('submit', this.submitForm.bind(this));
		this.form = document.querySelector('.registration__form');
		this.handleFormInputs();
		this.handleSelectChange('.registration__form', 'select[name="fachrichtung"]', '.fachrichtung__label', 'Sonstige');
		this.handleSelectChange('.contact__form', 'select[name="anliegen"]', '.anliegen__label', 'Sonstige');
		this.handleSelectChange('.login__form', 'select[name="fachrichtung"]', '.fachrichtung__label', 'Sonstige');
		this.handleEnableButton('.login__form')
	}

	handleEnableButton(formEl) { 
		const form = document.querySelector(formEl);
		if(!form) return
		const button = form.querySelector('button');
		const inputs = form.querySelectorAll('input');
		const selects = form.querySelectorAll('select');

		inputs.forEach(input => { 
			input.addEventListener('input', () => { 
				let valid = true;
				inputs.forEach(input => { 
					if (input.value === '') { 
						valid = false;
					}
				});
				if (valid) { 
					button.removeAttribute('disabled');
				} else { 
					button.setAttribute('disabled', 'disabled');
				}
			});
		});

		if (selects.length === 0) return

			selects.forEach(select => {
				select.addEventListener('change', () => {
					let valid = true;
					selects.forEach(select => {
						if (select.value === '' || select.value === 'Sonstige') {
							valid = false;
						}
					});
					if (valid) {
						button.removeAttribute('disabled');
					} else {
						button.setAttribute('disabled', 'disabled');
					}
				});
			}
			);
			
		

	
	}

	handleFormInputs() { 
		const inputs = document.querySelectorAll('input, textarea');
		inputs.forEach(input => {	
			if(input.classList.contains('ccm--ctrl-init')) return
			this.checkFormInput(input);
			input.addEventListener('focus', () => {
				input.closest('label').classList.add('filled');
			});
			
			input.addEventListener('blur', () => {
				this.checkFormInput(input);
				if (input.type === 'email' && input.name === 'email-confirm') {
					input.addEventListener('blur', () => { 
						this.validateEMailMatch();
					})
				}
			});
		});
	}

	handleSelectChange(formEl, el, label, compareValue) { 
		const form = document.querySelector(formEl);
		if(!form) return
		const selectElement = form.querySelector(el);
		const fachrichtungAndere = form.querySelector(label);
		console.log(form, selectElement, fachrichtungAndere, compareValue)
		selectElement.addEventListener('change', (e) => {
			console.log(e.target.value)
			if (e.target.value === compareValue) {
				fachrichtungAndere.classList.add('visible');
			} else { 
				fachrichtungAndere.classList.remove('visible');
			}
		});
	}
	
	validateEMailMatch() {
		
		if (!document.querySelector('input[name="email-confirm"]')) return
		const emailConfirmInput = document.querySelector('input[name="email-confirm"]');
		const form = emailConfirmInput.closest('form');
		const emailInput = form.querySelector('input[name="email"]');
		const emailError = form.querySelector('.email__error');

		if (emailInput.value !== emailConfirmInput.value) {
			emailError.innerHTML = '<p>E-Mail Adressen stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.</p>';
			emailInput.closest('label').classList.add('error');
			emailConfirmInput.closest('label').classList.add('error');
		} else { 
			emailError.innerHTML = '';
			emailInput.closest('label').classList.remove('error');
			emailConfirmInput.closest('label').classList.remove('error');
		}

	}

	checkFormInput(input) { 
		if (input.value === '') {
			input.closest('label').classList.remove('filled');
		} else {
			input.closest('label').classList.add('filled');
		}
	}

//   submitForm(e) {
// 	e.preventDefault();
// 	const data = new FormData(this.form);
// 	const formObj = {};
// 	data.forEach((value, key) => {
// 	  formObj[key] = value;
// 	});
// 	console.log(formObj);
// 	this.form.reset();
//   }
}


window.addEventListener('DOMContentLoaded', () => {
	const RegistrationForm = new LillyForm('#registration');	
	const LoginForm = new LillyForm('#login_form');	
});