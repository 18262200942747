class Countdown {
	constructor(selector, date) {
		this.selector = document.querySelector(selector);
		this.date = new Date(date).getTime();
		this.interval = null;
		this.init();
		this.runTimer = true
	}

	init() {
		this.interval = setInterval(() => {
			this.update();
			this.timedContent();			
		}, 1000);
	}

	timedContent() { 
		if(this.runTimer === false) return;
		const timedContent = document.querySelectorAll('[data-timed]') || false;
		if (!timedContent) return;
		timedContent.forEach(content => {
			const timedContentDate = content.getAttribute('data-timed');
			const timedContentDateValue = new Date(timedContentDate).getTime();
			const now = new Date().getTime();
			const distance = timedContentDateValue - now;
			if (distance < 0) {
				content.style.display = '';
				this.runTimer = false;
				return;
			}
		})
		
	}

	update() {
		if( !this.selector ) return;
		const now = new Date().getTime();
		const distance = this.date - now;

		if (distance < 0) {
			clearInterval(this.interval);
			this.selector.querySelector('[data-time="days"]').innerHTML = "00";
			this.selector.querySelector('[data-time="hours"]').innerHTML = "00";
			this.selector.querySelector('[data-time="minutes"]').innerHTML = "00";
			this.selector.querySelector('[data-time="seconds"]').innerHTML = "00";
			return;
		}

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		const daysLayout = this.timeLayout(days);
		const hoursLayout = this.timeLayout(hours)
		const minuteLayout = this.timeLayout(minutes)
		const secondLayout = this.timeLayout(seconds)

		this.selector.querySelector('[data-time="days"]').innerHTML = daysLayout;
		this.selector.querySelector('[data-time="hours"]').innerHTML = hoursLayout;
		this.selector.querySelector('[data-time="minutes"]').innerHTML = minuteLayout;
		this.selector.querySelector('[data-time="seconds"]').innerHTML = secondLayout;

	}

	timeLayout(time) {
		return `${time < 10 ? "0" + time : time}`
	 }
}

